import {message} from "antd"
import { observer } from "mobx-react-lite"
import { FC, useContext, useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { Context } from "../../.."
import {ApiService} from "../../../core/api/api"
import {StorageKeys} from "../../../core/utils/defaults"

import styles from "../../../styles/AdminManager.module.scss"

const CoreManager: FC = () => {

  const { store, adminStore } = useContext(Context)
  const location = useLocation()

  const [seoTitle, setSeoTitle] = useState("")
  const [seoDesc, setSeoDesc] = useState("")
  const [seoKeyws, setSeoKeyws] = useState("")
  const [seoColor, setSeoColor] = useState("")

  const [adminPwd, setAdminPwd] = useState("")
  const [adminPwdRpt, setAdminPwdRpt] = useState("")

  const [siteTheme, setSiteTheme] = useState("summer")

  const [isSeoLoading, setIsSeoLoading] = useState(false)

  const fetchData = () => {
    setSeoTitle(adminStore.metaData.title)
    setSeoDesc(adminStore.metaData.description)
    setSeoKeyws(adminStore.metaData.keywords)
    setSeoColor(adminStore.metaData.color)

    setSiteTheme(store.theme)
  }

  const saveSeoAction = async () => {
    setIsSeoLoading(prev => prev = true)

    let newMeta: any = {
      title: seoTitle,
      description: seoDesc,
      keywords: seoKeyws,
      color: seoColor
    }

    const res = await ApiService.core.updateSetting(2, {
      data: newMeta
    })

    adminStore.setMetaData(newMeta)
    localStorage.setItem(StorageKeys.SETTINGS_META, JSON.stringify(newMeta))

    setIsSeoLoading(prev => prev = false)
  }

  const changePwd = async () => {
    if (adminPwd.length < 3) {
      message.warning("Пароль должен быть длинной более трех символов!")
      return
    }

    if (adminPwd != adminPwdRpt) {
      message.warning("Пароли не совпадают!")
      return
    }

    const res = await ApiService.users.changeAdminPwd(adminPwd)
  
    setAdminPwd(prev => prev = "")
    setAdminPwdRpt(prev => prev = "")

    message.success("Пароль успешно изменен!")
  }

  const isPwdFilled = () => {
    if (
      adminPwd != "" &&
      adminPwdRpt != ""
    ) {
      return true
    } else {
      return false
    }
  }

  const isSeoChanged = () => {
    if (
      adminStore.metaData.title != seoTitle ||
      adminStore.metaData.description != seoDesc ||
      adminStore.metaData.keywords != seoKeyws ||
      adminStore.metaData.color != seoColor
    ) {
      return true
    } else {
      return false
    }
  }

  const isThemeChanged = () => {
    if (store.theme != siteTheme) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <div className="container">
        <div className="row">
          <div className={"col-12 col-md-6 " + styles.mobbed}>
            <div className={styles.setting_block}>
              <h3>Тема сайта</h3>
              <p>Вы имеете возможность менять тему сайта между зимней и летней</p>
              <div className={styles.setting_block__toggle}>
                <div className={styles.select}>
                  <select name="theme_site" id="theme_site" value={siteTheme} onChange={(event) => {
                    console.log(event)
                    setSiteTheme(event.target.value)
                  }}>
                    <option value="summer">Летняя</option>
                    <option value="winter">Зимняя</option>
                  </select>
                </div>
              </div>
              <button disabled={!isThemeChanged()}>Сохранить</button>
            </div>

            <div className={styles.setting_block} style={{marginTop: "32px"}}>
              <h3>Cменить пароль</h3>
              <p>Вы можете сменить пароль от входа в панель в управления</p>
              
              <div className={styles.xform}>
                <div className={styles.xform_input}>
                  <label htmlFor="new_pwd">Новый пароль</label>
                  <input type="text" id="new_pwd" placeholder="Новый пароль" value={adminPwd} onChange={(event) => setAdminPwd(event.target.value)} />
                </div>
                <div className={styles.xform_input}>
                  <label htmlFor="new_pwd_rpt">Повторите новый пароль</label>
                  <input type="text" id="new_pwd_rpt" placeholder="Повторите новый пароль" value={adminPwdRpt} onChange={(event) => setAdminPwdRpt(event.target.value)} />
                </div>
              </div>

              <button disabled={!isPwdFilled()} onClick={() => changePwd()}>Обновить</button>
            </div>
          </div>
          <div className={"col-12 col-md-6 " + styles.mobbed}>
            <div className={styles.setting_block}>
              <h3>SEO</h3>
              
              <div className={styles.xform}>
                <div className={styles.xform_input}>
                  <label htmlFor="seo_title">Заголовок страницы</label>
                  <input type="text" id="seo_title" placeholder="Заголовок" value={seoTitle} onChange={(event) => setSeoTitle(event.target.value)} />
                </div>
                <div className={styles.xform_input}>
                  <label htmlFor="seo_desc">Описание страницы</label>
                  <input type="text" id="seo_desc" placeholder="Описание" value={seoDesc} onChange={(event) => setSeoDesc(event.target.value)} />
                </div>
                <div className={styles.xform_input}>
                  <label htmlFor="seo_keys">Ключевые слова</label>
                  <input type="text" id="seo_keys" placeholder="Ключевые слова" value={seoKeyws} onChange={(event) => setSeoKeyws(event.target.value)} />
                </div>
                <div className={styles.xform_input}>
                  <label htmlFor="seo_color">Основной цвет</label>
                  <input type="text" id="seo_color" placeholder="Основной цвет" value={seoColor} onChange={(event) => setSeoColor(event.target.value)} />
                </div>
              </div>

              <button className={styles.xbtn} disabled={!isSeoChanged()} onClick={() => saveSeoAction()}>Сохранить</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(CoreManager)