import { observer } from "mobx-react-lite";
import React, { FC } from "react"
import { Route, Routes } from "react-router-dom"
import { summerRoutes, winterRoutes } from "../core/router"

const AppRouter: FC = () => {
  return (
    <React.Suspense fallback={<><div className="loading">{/* <LoadingOutlined rev={null} spin style={{fontSize: "42px", color: "#fff"}} /> */}</div></>}>
      <Routes>
        {summerRoutes.map(route => 
          <Route
            path={route.path}
            element={route.component}
            key={route.path}
            />
        )}
      </Routes>
    </React.Suspense>
  )
}

export default observer(AppRouter)